import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { MultiSelect } from 'react-multi-select-component';
import { useTranslation } from '../../utils/translation';

type SelectProps = {
  labelledBy: string;
  options: { value?: string; id?: string }[];
  initValue?: string[];
  multiple: boolean;
  onChange: (val: string[]) => void;
  allLabel?: string;
  icon?: boolean;
};

type OptionProps = {
  label: string;
  value: string;
};

const MultiDropdown = styled(MultiSelect)`
  margin: 15px 0 0;
  position: relative !important;

  .dropdown-container {
    border: solid 1px ${(props) => (props.theme.isDark ? '#555' : '#ccc')}!important;
  }

  .dropdown-content {
    z-index: 10 !important;
  }

  &.mod-top .dropdown-content {
    bottom: 45px !important;
    top: initial !important;
  }

  .dropdown-heading {
    cursor: pointer !important;
    border-radius: 3px;
  }

  .dropdown-heading,
  .dropdown-content *,
  .dropdown-content *:hover {
    background: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor}!important;
  }

  &.mod-icon .dropdown-heading {
    background: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor};
    height: 50px;
  }

  &.mod-icon .dropdown-heading-value .gray {
    align-items: center;
    color: ${(props) => (props.theme.isDark ? 'white' : props.theme.palette.black)}!important;
    display: flex;

    &::before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 -960 960 960'%3E %3Cpath fill='%23${(
        props,
      ) =>
        props.theme.isDark
          ? 'FFFFFF'
          : '212C42'}' d='M440-160q-17 0-28.5-11.5T400-200v-240L168-736q-15-20-4.5-42t36.5-22h560q26 0 36.5 22t-4.5 42L560-440v240q0 17-11.5 28.5T520-160h-80Zm40-308 198-252H282l198 252Zm0 0Z'/%3E %3C/svg%3E");
      padding: 0 15px 0 5px;
    }
  }
`;

export const Select = ({ options, onChange, multiple, initValue, labelledBy, allLabel, icon }: SelectProps) => {
  const dropdownOptions = useMemo(
    () =>
      options.map((item) => {
        return { label: item?.value, value: item?.id };
      }) ?? [],
    [options],
  );

  const [isPlacementAsTop, setPlacementAsTop] = useState(false);

  const [selected, setSelected] = useState<OptionProps[]>(
    dropdownOptions.filter((option) => initValue?.includes(option.value)),
  );

  const t = useTranslation();

  const handleChange = (e: Array<OptionProps>) => {
    setSelected(e);
    const values = Array.from(e).map((option) => option.value);
    onChange(values);
  };

  const displayValue = (o: Array<OptionProps>) => {
    if (o.length === 0) {
      return allLabel || t('design.select.all');
    }

    return o.map((option: OptionProps) => option.label).join(', ');
  };

  const getPlacement = (isOpen: boolean) => {
    if (isOpen) {
      const el = document.querySelector(`[aria-labelledby="${labelledBy}"]`);
      const distancePopoverAndBottom = window.innerHeight - el.getBoundingClientRect().bottom;

      setPlacementAsTop(distancePopoverAndBottom < 8 + 44 * options.length);
    }
  };

  return multiple ? (
    <MultiDropdown
      labelledBy={labelledBy}
      valueRenderer={displayValue}
      options={dropdownOptions}
      value={selected}
      onChange={handleChange}
      disableSearch
      hasSelectAll={false}
      shouldToggleOnHover={false}
      onMenuToggle={getPlacement}
      className={(icon ? 'mod-icon ' : '') + (isPlacementAsTop ? 'mod-top' : '')}
    />
  ) : null;
};
