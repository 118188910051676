import React from 'react';
import { LogoProps } from '../types';

function Ios({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill={color}
        d="M1.86.5A1.36,1.36,0,0,0,.5,1.86V14.14A1.36,1.36,0,0,0,1.86,15.5H14.14a1.36,1.36,0,0,0,1.36-1.36V1.86A1.36,1.36,0,0,0,14.14.5ZM3.11,5.41a.49.49,0,1,1,0,1c-.35,0-.56-.2-.56-.48S2.76,5.41,3.11,5.41Zm3.58.23A2.2,2.2,0,0,1,9,8.11a2.21,2.21,0,0,1-2.26,2.48A2.21,2.21,0,0,1,4.42,8.11,2.22,2.22,0,0,1,6.69,5.64Zm4.88,0c1,0,1.77.58,1.8,1.42h-.92a.8.8,0,0,0-.87-.62c-.49,0-.82.23-.82.59s.23.46.78.57l.56.12c1,.2,1.35.61,1.35,1.34,0,.95-.72,1.53-1.9,1.53S9.7,10,9.66,9.16h1c0,.38.43.63,1,.63s.87-.25.87-.61-.23-.47-.81-.59l-.61-.13c-.86-.18-1.28-.63-1.28-1.35C9.77,6.23,10.49,5.64,11.57,5.64Zm-4.88.87c-.77,0-1.26.62-1.26,1.6s.48,1.61,1.26,1.61S7.94,9.1,7.94,8.11,7.45,6.51,6.69,6.51Zm-4.06.36h1v3.6h-1Z"
      />
    </svg>
  );
}

export default Ios;
