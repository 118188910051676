import React from 'react';
import type { LogoPropsOptionalColor } from '../types';

const StarHalf = ({ width, height, color }: LogoPropsOptionalColor) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={width} height={height}>
      <path
        d="M24 14.45v16.2l6.85 5.2-2.75-8.65 6.3-4.1h-7.55ZM11.65 44l4.65-15.2L4 20h15.2L24 4l4.8 16H44l-12.3 8.8L36.35 44 24 34.6Z"
        fill={color}
      />
    </svg>
  );
};

export default StarHalf;
