import type { SponsoredComponentProps } from '@marty-js/design/src/molecules/sponsored-component';
import type { SponsoData } from '../types';

export const toSponsoredComponentProps = (sponsoData: SponsoData): SponsoredComponentProps => {
  const data = sponsoData?.data?.data;

  if (!data) {
    return null;
  }

  const url = Object.keys(data)
    .map((key: string): string => {
      if (!key.match(/Url$/)) {
        return null;
      }

      const value = (data as any)[key];

      return value !== null && value !== '' ? value : null;
    })
    .find((currentUrl) => {
      return !!currentUrl;
    });

  return {
    tops: data.columns,
    title: data.sticker,
    description: data.lead,
    imageId: data.imageId,
    sticker: data.label,
    url,
    buttonLabel: data.buttonLabel,
  };
};
