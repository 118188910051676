import React, { useMemo } from 'react';
import StarHalf from '../icons/material-symbols/star_half';
import Star from '../icons/material-symbols/star';

export interface StarsProps {
  rating: number;
  max: number;
}

type StarConfig = {
  color: string;
  icon: any;
};

export const Stars = (props: StarsProps) => {
  const { rating, max } = props;

  const generateStar = (mark: number, placement: number): StarConfig => {
    const roundScore = Math.round(mark * 2) / 2;

    const icon = roundScore < placement && roundScore > placement - 1 ? StarHalf : Star;
    const color = roundScore >= placement || icon === StarHalf ? '#FFC700' : '#E5E8EE';

    return { icon, color };
  };

  const stars = useMemo(() => {
    const newStars: StarConfig[] = [];
    for (let i = 1; i <= max; i += 1) {
      newStars[i] = generateStar(rating, i);
    }

    return newStars;
  }, [rating, max]);

  return (
    <>
      {stars.map((star, i) => (
        <star.icon key={i} w color={star.color} width={20} height={20} />
      ))}
    </>
  );
};
