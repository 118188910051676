import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';

export const HeadingTwo = styled.h2`
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 26px;
  font-weight: bold;
  line-height: 1.38;
  margin: var(--spacer-s) 0;

  &.mod-medium {
    color: ${(props) => props.theme.colors.medium};
  }

  &.mod-discarded {
    margin: var(--spacer-s) 0 var(--spacer);
  }

  &.mod-without-mt {
    margin: 0 0 var(--spacer-s);
  }

  &.mod-without-mb {
    margin: var(--spacer-s) 0 0;
  }

  &.mod-fat {
    color: ${(props) => props.theme.colors.medium};
    font-size: 30px;
    font-weight: 900;
  }

  span {
    display: block;
    font-size: 18px;
    color: ${(props) => props.theme.colors.medium};
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      &.mod-huge {
        font-size: 34px;
      }

      &.mod-fat {
        font-size: 48px;
      }

      span {
        font-size: 22px;
      }
    `,
  )}
`;
