import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useLinkComponent } from '../utils/component';
import { EventType } from '../atoms/types';

const StyledCta = styled.div`
  background: ${(props) => props.theme.palette.red};
  border-radius: 6px;
  border: solid 2px ${(props) => props.theme.palette.red};
  cursor: pointer;
  font-weight: bold;
  position: relative;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background: ${(props) => props.theme.palette.darkRed};
    border: solid 2px ${(props) => props.theme.palette.darkRed};
  }

  > a,
  > span {
    color: ${(props) => props.theme.palette.white};
    display: block;
    padding: 10px 13px;
    font-size: 18px;
    line-height: 1.62;
    text-decoration: none;

    ${mq.lte(
      DEVICE_SIZE.LARGE,
      css`
        padding: 8px 10px;
        font-size: 16px;
      `,
    )}
  }

  &.mod-inline {
    display: inline-block;
  }

  &.mod-sm a,
  &.mod-sm span {
    font-size: 16px;
    padding: 8px 10px;
  }

  &.mod-primary-font {
    font-family: ${(props) => props.theme.typography.primaryFont};
  }

  &.mod-green {
    background-color: ${(props) => props.theme.palette.green};
    border: solid 2px ${(props) => props.theme.palette.green};

    &:hover {
      background-color: ${(props) => props.theme.palette.darkGreen};
      border: solid 2px ${(props) => props.theme.palette.darkGreen};
    }
  }

  &.mod-blue {
    background-color: ${(props) => props.theme.palette.blue};
    border: solid 2px ${(props) => props.theme.palette.blue};

    &:hover {
      background-color: ${(props) => props.theme.palette.darkBlue};
      border: solid 2px ${(props) => props.theme.palette.darkBlue};
    }

    &.mod-empty {
      background: none;
      border: solid 2px ${(props) => props.theme.palette.blue};
      color: ${(props) => (props.theme.isDark ? 'white' : props.theme.palette.blue)};

      a,
      span {
        color: ${(props) => (props.theme.isDark ? 'white' : '#3F547E')};
      }

      &:hover {
        background-color: #3f547e;

        a {
          color: white;
        }
      }
    }
  }
`;

export type CtaProps = {
  className?: string;
  title?: string;
  href?: string;
  rel?: string[];
  referer?: string;
  openInNewWindow?: boolean;
  stylized?: boolean;
  onClick?: any;
  arrow?: boolean;
  event?: EventType;
  inline?: boolean;
  noLink?: boolean;
};

export const Cta = ({ ...props }: React.PropsWithChildren<CtaProps>) => {
  const LinkComponent = useLinkComponent();
  const { className, children, noLink } = props;
  const [isLink, setIsLink] = useState<boolean>(!noLink);
  const hover = useCallback(() => (isLink ? null : setIsLink(true)), [isLink, setIsLink]);
  props.className = '';

  return (
    <StyledCta onMouseEnter={hover} className={classNames(className, { 'mod-inline': props.inline })}>
      {isLink ? <LinkComponent {...props} /> : <span>{children}</span>}
    </StyledCta>
  );
};
