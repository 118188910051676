import React, { ChangeEvent, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useTranslation } from '../utils/translation';
import ArrowDown from '../icons/arrowDown';

const LineWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  font-size: 18px;
  font-weight: 700;
  margin: 20px 0 30px;
  position: relative;
  white-space: nowrap;
  width: 100%;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      &.mod-simplified {
        background: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor}!important;
        border: solid 1px ${(props) => (props.theme.isDark ? '#555' : '#ccc')};
        border-radius: 3px;
        flex-direction: row;
        margin-top: 15px;

        &::before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E %3Cpath fill='%23${(
            props,
          ) =>
            props.theme.isDark
              ? 'FFFFFF'
              : '212C42'}' d='m6 20 4-4H7V4H5v12H2zm5-12h9v2h-9zm0 4h7v2h-7zm0-8h11v2H11zm0 12h5v2h-5z'/%3E %3C/svg%3E");
          line-height: 16px;
          padding: 0 5px 0 15px;
        }
      }
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0 0 30px;
      flex-direction: row;
    `,
  )}
`;

const LineWrapperRight = styled.div`
  display: none;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      position: absolute;
      right: 15px;
    `,
  )}
`;

const Line = styled.hr`
  border: none;
  display: flex;
  border-top: 2px dotted #99a4ba;
  height: 1px;
  margin: 5px;
  width: 80%;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      .mod-simplified & {
        display: none;
      }
    `,
  )}
`;

const DropDown = styled.select`
  font-family: ${(props) => props.theme.typography.primaryFont};
  text-decoration: underline;
  text-underline-offset: 1px;
  border: none;
  background: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor};
  cursor: pointer;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 16px;
      -webkit-appearance: auto;
      -moz-appearance: auto;
      appearance: auto;
    `,
  )}

  :hover {
    text-decoration: underline;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      .mod-simplified & {
        background: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor}!important;
        height: 50px;
        display: flex;
        align-items: center;
        width: 100%;
        text-decoration: none;
        padding: 0 10px;
        font-weight: initial;
        color: ${(props) => (props.theme.isDark ? 'white!important' : '#232728!important')};
        font-size: initial;
        font-family: ${(props) => props.theme.typography.defaultFont};
      }
    `,
  )}
`;

const Result = styled.div`
  background: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor};
  padding: 0 8px;
  font-size: 14px;
  position: absolute;
  right: 0;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      .mod-simplified & {
        display: none;
      }
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: initial;
      font-weight: bold;
      left: 37px;
      right: initial;
    `,
  )}
`;

const Option = styled.option``;

export const ResultsOrder = ({
  resultNum,
  sortOptions,
  currentSort,
  callbackPost,
  simplified,
}: {
  resultNum: number;
  sortOptions: { [key: string]: string };
  currentSort: string;
  callbackPost: (val: string) => void;
  simplified?: boolean;
}) => {
  const onChange = useCallback(
    (newSortValue: string): void => {
      callbackPost(newSortValue);
    },
    [callbackPost],
  );

  const trans = useTranslation();

  const selectOptions: { key: string; value: string }[] = useMemo(() => {
    const orderingPattern = /(?<toRemove>\|\|(?<order>ASC|DESC))$/i;
    const tmpSelectOptions: { key: string; value: string }[] = [];
    if (sortOptions) {
      Object.keys(sortOptions).forEach((value: string, key: number) => {
        const label = Object.values(sortOptions)[key];
        const orderMatch = value.match(orderingPattern);

        let orderLabel = '';
        if (orderMatch && orderMatch?.groups?.order) {
          orderLabel = trans(`design.sortable.__ordering-${orderMatch.groups.order.toLowerCase()}__`);
        }

        let correctLabel = label + (orderLabel ? ` ${orderLabel}` : '');
        if (label.indexOf('__sortable-') === 0) {
          if (label !== '__sortable-price__') {
            orderLabel = '';
          }
          correctLabel = trans(`design.sortable.${label}`) + (orderLabel ? ` ${orderLabel}` : '');
        }

        tmpSelectOptions.push({ key: value, value: correctLabel });
      });
    }

    return tmpSelectOptions;
  }, [sortOptions, trans]);

  return (
    <LineWrapper className={simplified ? 'mod-simplified' : ''} data-testid="results-order">
      <Result data-testid="results-order-result">
        {resultNum} {trans('design.results-order.results')}
      </Result>
      <Line />
      <DropDown
        data-testid="results-order-dropdown"
        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
          onChange(event.target.value);
        }}
        value={currentSort}
      >
        {selectOptions.map((option: { key: string; value: string; selected: boolean }) => (
          <Option key={option.key} value={option.key}>
            {trans('design.results-order.orderBy')} : {option.value}
          </Option>
        ))}
      </DropDown>
      <LineWrapperRight>
        <ArrowDown width={15} height={15} color="#AAA" />
      </LineWrapperRight>
    </LineWrapper>
  );
};
