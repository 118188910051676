import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useLinkComponent } from '../utils/component';
import { useThemeSwitcher } from '../utils/theme-switcher';
import CirclePlus from '../icons/circle-plus';

const Wrapper = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 0 0 30px;

  ${mq.gte(DEVICE_SIZE.LARGE, css``)}
`;

const Category = styled.li`
  border-bottom: solid 1px #e5e8ee;
  font-size: 18px;
  line-height: 24px;
  padding: 10px 0;
`;

const CategoryHeader = styled.div`
  align-items: center;
  display: flex;
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-weight: 700;
  justify-content: space-between;
  transition: color 0.3s ease;
  user-select: none;

  > * {
    cursor: pointer;
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const CategoryHeaderArrow = styled.div`
  display: flex;
  transition: transform 0.3s ease;

  .mod-extended & {
    transform: rotate(45deg);
  }
`;

const Items = styled.ul`
  margin: 10px 0 0;
  transition: all 0.3s ease;
  overflow: hidden;

  &.mod-closed {
    margin: 0;
    max-height: 0 !important;
  }
`;

const Item = styled.li`
  border-left: solid 1px ${(props) => props.theme.colors.primary};
  padding: 5px 15px;
  transition: all 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export type SectionProps = {
  title?: string;
  url?: string;
  children?: SectionProps[];
};

export type SectionsProps = {
  sections?: SectionProps[];
};

export const SectionNavigation = ({ sections }: SectionsProps) => {
  const LinkComponent = useLinkComponent();
  const { currentTheme } = useThemeSwitcher();
  const [areOpenMenus, setAreOpenMenu] = useState<boolean[]>([]);

  const toggleDropdown = (index: number) => {
    const areOpenMenusCopy = [...areOpenMenus];
    areOpenMenusCopy[index] = !areOpenMenusCopy[index];
    setAreOpenMenu(areOpenMenusCopy);
  };

  return (
    <Wrapper>
      {sections
        ? sections.map((section, index) => {
            if (section) {
              if (section?.children) {
                return (
                  <Category key={index} className={areOpenMenus[index] ? 'mod-extended' : ''}>
                    <CategoryHeader>
                      <LinkComponent href={section.url}>{section.title}</LinkComponent>
                      <CategoryHeaderArrow onClick={() => toggleDropdown(index)}>
                        <CirclePlus
                          width={22}
                          height={22}
                          color={currentTheme.isDark ? currentTheme.palette.white : currentTheme.palette.blackAlt}
                        />
                      </CategoryHeaderArrow>
                    </CategoryHeader>
                    {section.children?.length ? (
                      <Items
                        className={areOpenMenus[index] ? '' : 'mod-closed'}
                        style={{ maxHeight: 40 * section.children?.length }}
                      >
                        {section.children?.map((s, subIndex) =>
                          s ? (
                            <Item key={`s-${subIndex}`}>
                              <LinkComponent href={s.url}>{s.title}</LinkComponent>
                            </Item>
                          ) : null,
                        )}
                      </Items>
                    ) : null}
                  </Category>
                );
              }

              return (
                <Category key={index}>
                  <CategoryHeader>
                    <LinkComponent href={section.url}>{section.title}</LinkComponent>
                  </CategoryHeader>
                </Category>
              );
            }

            return null;
          })
        : null}
    </Wrapper>
  );
};
