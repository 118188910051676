import React from 'react';
import { LogoProps } from './types';

function Categories({ width, height, color }: LogoProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} fill={color} viewBox="0 -960 960 960">
      <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
    </svg>
  );
}

export default Categories;
