import React from 'react';
import { LogoProps } from '../types';

function Windows({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8758 0.499756H0.5V11.8756H11.8758V0.499756Z" fill={color} />
      <path d="M24.4998 0.499756H13.124V11.8756H24.4998V0.499756Z" fill={color} />
      <path d="M11.8758 13.1244H0.5V24.5002H11.8758V13.1244Z" fill={color} />
      <path d="M24.4998 13.1244H13.124V24.5002H24.4998V13.1244Z" fill={color} />
    </svg>
  );
}

export default Windows;
