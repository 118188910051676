import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HeadingOne } from '@marty-js/design/src/atoms/h1';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { ItemCtaSimple } from '@marty-js/design/src/molecules/item-cta-simple';
import { SectionNavigation } from '@marty-js/design/src/molecules/section-navigation';
import { Item } from '@marty-js/design/src/molecules/download/item';
import { ItemSponsored } from '@marty-js/design/src/molecules/download/item-sponsored';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { ResultsOrder } from '@marty-js/design/src/molecules/results-order';
import { AggsDataSheetAttribute, GenericItem } from '@marty-js/api-sdk/types';
import { useGetGenericItemBySection } from '@marty-js/api-sdk/services/genericItemBySection';
import { Select } from '@marty-js/design/src/atoms/filters/select';
import Close from '@marty-js/design/src/icons/close';
import Categories from '@marty-js/design/src/icons/categories';
import { useThemeSwitcher } from '@marty-js/design/src/utils/theme-switcher';
import { GetGenericItemBySectionVariables } from '@marty-js/api-sdk/__generated__/GetGenericItemBySection';
import { DataSheetFilter } from '@marty-js/api-sdk/__generated__/globalTypes';
import classNames from 'classnames';
import { useTrackEvent } from '@marty-js/api-sdk/services/matomo';
import dynamic from 'next/dynamic';
import { DateDisplayAsString } from '../../../atoms/date/date';
import { Paginator } from '../../../atoms/paginator';
import { useMetaSeoContext, useSetMetaTitleIfNull, useSetPageNumber } from '../../../utils/metaSeoContext';
import type { DiscoveryOfMomentData, DiscoveryOfTheMoment, SponsoData } from '../../../types';
import { Container, MainContent, SideContent, SideContentSpacer } from '../../../atoms/grid-container';
import { Breadcrumb } from '../../../atoms/breadcrumb';
import type {
  LayoutSectionProps,
  PluginArticleProcessorLight,
  PluginFilterAggs,
  PluginGenericItemByQueryConfig,
} from '../types';
import { Ad } from '../../../ads/ad';
import { useInfiniteScroll } from '../../../utils/infinite-scroll.hooks';
import { SlateContentPlugin } from '../../../article-processor/plugins/slate.content';
import { Cell } from '../../../article-processor/types';
import { Spinner } from '../../../utils/spinner';
import editorSlateToText from '../../../utils/editorSlateToText';
import { useAddServerSideZones } from '../../../ads/AdContext';
import { toSponsoredComponentProps } from '../../../utils/componentReducer';

const Sponso = dynamic(() => import('@marty-js/design/src/molecules/sponsored-component'));

const SideContentBottom = styled.div`
  display: none;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
      position: sticky;
      top: 10px;
      margin-top: 30px;
    `,
  )}
`;

const DownloadSideContent = styled(SideContent)`
  margin-top: -164px;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin-top: 0 !important;
    `,
  )}
`;

const Introduction = styled.div`
  display: -webkit-box;
  margin: 0 0 15px;

  &.mod-child {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &.mod-open {
    -webkit-line-clamp: initial;
  }
`;

const ReadMore = styled.div`
  cursor: pointer;
  display: block;
  text-decoration: underline;
  text-underline-offset: 1px;
  min-height: 21px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 18px;
    `,
  )}
`;

const Title = styled.span`
  color: ${(props) => (props.theme.isDark ? props.theme.palette.white : props.theme.palette.black)};
  display: block;
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;

  &:first-of-type {
    margin: 30px 0 20px;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 26px;
    `,
  )}
`;

const ListItemRow = styled.ul`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      gap: 30px;
    `,
  )}
`;

const HeadingSponsored = styled.div`
  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      order: initial;
    `,
  )}
`;

const Spacer = styled.div`
  margin: 0 0 45px;
`;

const AdWrapper = styled.div`
  margin: 15px -20px 30px;
  max-width: 100vw;

  &.mod-desktop-only {
    ${mq.lte(
      DEVICE_SIZE.LARGE,
      css`
        display: none;
      `,
    )}
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 15px 0 60px;
      max-width: initial;

      &.mod-mobile-only {
        display: none;
      }
    `,
  )}
`;

const Overlay = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      background: ${(props) => props.theme.palette.blueMedium};
      bottom: 0;
      content: '';
      top: 0;
      left: 0;
      position: fixed;
      right: 0;
      z-index: 1000;
      transition: background 0.3s;
    `,
  )}
`;

const RefineMenu = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin: 0 0 20px;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      background: ${(props) => props.theme.backgroundTheme.contrast.backgroundColor};
      left: -100vw;
      max-height: 100vh;
      min-height: 100vh;
      right: 100vw;
      transition: left 0.6s, right 0.3s;
      overflow-y: auto;
      position: fixed;
      z-index: -1;

      &.mod-open {
        left: 0;
        padding: 15px;
        right: 20px;
        top: 0;
        z-index: 10000;
      }
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      left: initial;
      position: initial;

      &.mod-open {
        max-height: initial;
        position: initial;
      }
    `,
  )}
`;

const RefineMenuTop = styled.div`
  align-items: center;
  border-bottom: 1px dashed #e5e8ee;
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
  padding: 20px 0;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      border: none;
      margin: 0;
      padding: 0;
    `,
  )}
`;

const RefineMenuTopTitle = styled.span`
  color: ${(props) => props.theme.palette.blueMedium};
  font-size: 12px;
  font-weight: 700;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const RefineMenuClose = styled.div`
  cursor: pointer;
  font-size: 20px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const TitleCategories = styled.div`
  align-items: center;
  background: #3f547e;
  border-radius: 6px;
  color: white;
  display: flex;
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 22px;
  font-weight: 600;
  gap: 15px;
  line-height: 1.38;
  margin: 15px 0 0;
  padding: 12px 15px;
  cursor: pointer;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      cursor: initial;
    `,
  )}
`;

const IconCategories = styled.div`
  display: flex;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const SpinnerContainer = styled.div`
  display: none;
  text-align: center;

  &.mod-loading {
    display: block;
  }
`;

const ListingOrder = styled.div`
  align-items: center;
  display: flex;
  order: -1;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      order: initial;

      * {
        background: ${(props) => (props.theme.isDark ? '#232728' : props.theme.palette.white)};
      }
    `,
  )}
`;

const MobileCount = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 30px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const DesktopOnly = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const HeadingSponsoredLoading = styled.div`
  height: 257px;
  background: ${(props) => (props.theme.isDark ? props.theme.palette.blackAlt : props.theme.palette.grey)};
`;

const SidebarSponsoredLoading = styled.div`
  margin-top: 30px;
  height: 270px;
  background: ${(props) => (props.theme.isDark ? props.theme.palette.blackAlt : props.theme.palette.grey)};
`;

export type DownloadListingSectionData = {
  children: {
    PLUGIN_NAME: string;
    config: { type: string; objectKey: string };
    sectionList: { id: string; title: string; url: string }[];
    templateSectionConfig: unknown;
  };
  mainFlux: PluginGenericItemByQueryConfig;
  discoveryOfTheMoment: { data: { data: DiscoveryOfTheMoment } };
  topDownload: any;
  sections: any;
  filterAggs: PluginFilterAggs;
  textJson: PluginArticleProcessorLight;
};

const KeyWrapper: React.FC<PropsWithChildren<any>> = ({ children }) => {
  return children;
};

const DownloadListing: React.FC<LayoutSectionProps<DownloadListingSectionData>> = ({
  data,
  pageInfo,
  breadcrumb,
  section,
}) => {
  const t = useTranslation();
  const [trackEvent] = useTrackEvent();
  const [isDefaultFlux, setDefaultFlux] = useState<boolean>(true);
  const [genericItems, setGenericItems] = useState<GenericItem[]>(data.mainFlux?.genericItemList);
  const [total, setTotal] = useState<number>(data.mainFlux?.info?.total);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [currentSort, setCurrentSort] = useState<string>(data.filterAggs.currentSort);
  const defaultFilters = useMemo(() => {
    const currentFilter: DataSheetFilter[] = [];
    if (typeof data?.filterAggs?.currentFilter === 'object') {
      Object.keys(data.filterAggs.currentFilter).forEach((key) => {
        if (key !== 'operating-system') {
          currentFilter.push({
            attributeShortSlug: key,
            values: data.filterAggs.currentFilter[key].values,
          });
        }
      });
    }

    return currentFilter;
  }, [data?.filterAggs?.currentFilter]);
  const [filters, setFilters] = useState<DataSheetFilter[]>(defaultFilters);
  const [isReadMoreOpen, setIsReadMoreOpen] = useState<boolean>(false);
  const introductionRef = useRef<HTMLParagraphElement>(null);

  const zoneSponso = useMemo(() => ({ zoneSlug: 'sponso-sidebar', keywords: '' }), []);
  const dataSponsoSidebar = useAddServerSideZones(zoneSponso);
  const zoneDiscovery = useMemo(() => ({ zoneSlug: 'discovery-of-the-moment', keywords: '' }), []);
  const dataDiscoveryOfTheMoment = useAddServerSideZones(zoneDiscovery);

  const discoveryOfTheMoment: DiscoveryOfMomentData = useMemo(() => {
    if (dataDiscoveryOfTheMoment?.component?.data) {
      const componentData = dataDiscoveryOfTheMoment.component.data;
      dataDiscoveryOfTheMoment.impressionCount();

      return componentData.data;
    }

    return null;
  }, [dataDiscoveryOfTheMoment]);

  const sponsoSidebar = useMemo(() => {
    if (dataSponsoSidebar) {
      const sponsoData = toSponsoredComponentProps(dataSponsoSidebar.component?.data as SponsoData);
      if (sponsoData) {
        dataSponsoSidebar.impressionCount();
      }

      return sponsoData;
    }

    return null;
  }, [dataSponsoSidebar]);

  const size = data.mainFlux?.info?.size ?? 10;
  let hatContent: Cell;
  try {
    hatContent = JSON.stringify(data.textJson.articleJson).length > 115 ? data.textJson.articleJson : null;
  } catch (e) {
    hatContent = null;
  }
  useSetMetaTitleIfNull(t('sdk.template.download_listing.metaTitle', { title: section.title, count: total }));
  useSetPageNumber(pageInfo.pageNumber);

  const metaSeoContext = useMetaSeoContext();

  if (!metaSeoContext.description && hatContent) {
    metaSeoContext.description = editorSlateToText(hatContent).replace(/(^.{180}.+?)\..+/g, '$1...');
  }

  const variables: GetGenericItemBySectionVariables = useMemo(() => {
    return {
      sectionId: pageInfo.section.id,
      from: 0,
      size,
      sort: currentSort,
      dataSheetFilters: filters,
    };
  }, [pageInfo.section.id, size, filters, currentSort]);

  const { fetchMoreData, fetchData, result, loading, called } = useGetGenericItemBySection({ variables });

  useEffect(() => {
    if (!loading) {
      if (result) {
        if (isDefaultFlux) {
          setGenericItems([...data.mainFlux.genericItemList, ...result.genericItemBySection.genericItems]);
          setTotal(data.mainFlux?.info?.total);
        } else {
          setGenericItems(result.genericItemBySection.genericItems);
          setTotal(result.genericItemBySection.total);
        }
      }
    }
  }, [setGenericItems, result, loading, isDefaultFlux, data.mainFlux, pageInfo.section.url]);

  const detectorRef = useInfiniteScroll(() => {
    if (isDefaultFlux || result) {
      const from = genericItems.length;
      if (called) {
        fetchMoreData({ from });
      } else if (!loading) {
        fetchData({ variables: { ...variables, from } });
      }
    }
  });

  const resultsOrderProps = useMemo(
    () => ({
      resultNum: total,
      sortOptions: data.filterAggs.allSortableSlugs,
      currentSort,
    }),
    [total, currentSort, data.filterAggs.allSortableSlugs],
  );

  const callbackPostSort = useCallback(
    (sort: string) => {
      fetchData({
        variables: {
          ...variables,
          sort,
        },
      });

      setDefaultFlux(false);
      setCurrentSort(sort);
    },
    [setCurrentSort, setDefaultFlux, fetchData, variables],
  );

  const callbackPostFilters = useCallback(
    (values: string[]) => {
      const dataSheetFilters = values?.length
        ? [
            ...defaultFilters,
            {
              attributeShortSlug: 'operating-system',
              values,
            },
          ]
        : defaultFilters;

      fetchData({
        variables: {
          ...variables,
          dataSheetFilters,
        },
      });

      setFilters(dataSheetFilters);
      setDefaultFlux(false);
    },
    [setFilters, setDefaultFlux, fetchData, variables, defaultFilters],
  );

  const { currentTheme } = useThemeSwitcher();

  const options: { value?: string; id?: string }[] = useMemo(
    () =>
      data.filterAggs?.aggsBuckets?.aggsDataSheetAttributes
        ?.filter(
          (aggsDataSheetAttribute: AggsDataSheetAttribute) =>
            aggsDataSheetAttribute.dataSheetAttribute.shortSlug === 'operating-system',
        )
        .flatMap((aggsDataSheetAttribute: AggsDataSheetAttribute) => {
          return aggsDataSheetAttribute?.buckets?.list?.flatMap((elem: any) => {
            return aggsDataSheetAttribute.dataSheetAttribute.enumValues.reduce((value: any, enumValue: any) => {
              if (+enumValue.id === +elem.value) {
                return enumValue;
              }

              return value;
            });
          });
        }),
    [data.filterAggs],
  );

  const getValueByShortSlug = useCallback((item: GenericItem, shortSlug: string): any => {
    return item.dataSheet?.groups?.flatMap((group) => {
      return group.attributes?.reduce((acc, spec) => {
        if (spec.shortSlug === shortSlug) {
          spec.value?.split(', ').map((osValue: string): number => {
            return acc.push(osValue);
          });
        }

        return acc;
      }, []);
    });
  }, []);

  const h1 = metaSeoContext.titleH1 ?? t('sdk.template.listing_download.title', { title: section.title, total });
  const heightHat = introductionRef?.current?.getBoundingClientRect()?.height;

  const clickTopApp = useCallback(
    (title: string) => {
      const userToken = localStorage.getItem('actionToken');
      trackEvent({
        variables: {
          userToken,
          eventCategory: 'BlocSidebar',
          eventName: 'topApp',
          actionName: 'topApp',
          eventValue: title,
          customDimensions: null,
          customVariables: null,
          url: pageInfo.section.url,
        },
      });
    },
    [pageInfo.section, trackEvent],
  );

  return (
    <>
      <Container className="mod-grid">
        <Ad className="Billboard_1" desktopOnly noBackground megaban />
        <MainContent>
          <Breadcrumb breadcrumb={breadcrumb} />
          <HeadingOne className="mod-smaller">{h1}</HeadingOne>
          {hatContent ? (
            <>
              <Introduction
                ref={introductionRef}
                data-testid="hat-content"
                className={classNames({ 'mod-open': isReadMoreOpen, 'mod-child': hatContent })}
              >
                {hatContent ? (
                  <SlateContentPlugin cell={hatContent} />
                ) : (
                  t('sdk.template.listing_download.introduction')
                )}
              </Introduction>
              <ReadMore onClick={() => setIsReadMoreOpen(true)}>
                {hatContent && !isReadMoreOpen && introductionRef.current?.clientHeight > 50 && t('sdk.read_more')}
              </ReadMore>
            </>
          ) : null}
        </MainContent>
        <MainContent className="mod-mobile-after">
          <Heading>
            {dataDiscoveryOfTheMoment?.isEmpty ? null : (
              <HeadingSponsored data-testid="item-sponsored">
                {dataDiscoveryOfTheMoment ? (
                  <>
                    <HeadingTwo>{t('sdk.template.listing_download.discovery_moment')}</HeadingTwo>
                    <ItemSponsored
                      title={discoveryOfTheMoment?.download?.title || ''}
                      description={discoveryOfTheMoment?.download?.description || ''}
                      image={{
                        imageId: discoveryOfTheMoment?.download?.image || '1',
                        alt: discoveryOfTheMoment?.download?.title || '',
                      }}
                      landingPage={discoveryOfTheMoment?.landingPage || null}
                      url={discoveryOfTheMoment?.download?.url || ''}
                      updatedAt={DateDisplayAsString({
                        dateTime: discoveryOfTheMoment?.download?.updatedAt,
                        pattern: 'dd/MM/yyyy',
                      })}
                    />
                  </>
                ) : (
                  <HeadingSponsoredLoading />
                )}
              </HeadingSponsored>
            )}
            <AdWrapper className="mod-mobile-only">
              <Ad className="Mobile_Pos1" mobileOnly />
            </AdWrapper>
            <HeadingTwo>
              {t('sdk.template.listing_download.all_softwares')} {section.title}
            </HeadingTwo>
            <MobileCount>
              {t('sdk.template.listing_download.result', { result: resultsOrderProps.resultNum })}
            </MobileCount>
            <ListingOrder>
              <ResultsOrder {...resultsOrderProps} callbackPost={callbackPostSort} simplified />
            </ListingOrder>
          </Heading>

          {loading ? (
            <SpinnerContainer className="mod-loading">
              <Spinner width={50} height={50} color="#99A4BA" />
            </SpinnerContainer>
          ) : (
            genericItems?.map((item: GenericItem, index: number) => {
              return (
                <KeyWrapper key={item.id}>
                  <Item
                    image={{
                      imageId: item.imageId,
                      alt: item.title,
                    }}
                    userRating={item.userRating / 20}
                    userRatingNb={item.userRatingNb}
                    title={item.title}
                    description={item.lead}
                    url={item.url}
                    updatedAt={DateDisplayAsString({ dateTime: item.updatedAt, pattern: 'dd/MM/yyyy' })}
                    os={getValueByShortSlug(item, 'operating-system')}
                  />
                  {index === 2 && (
                    <AdWrapper>
                      <Ad className="Content_1 Mobile_Pos2" />
                    </AdWrapper>
                  )}
                  {index === 12 && (
                    <AdWrapper>
                      <Ad className="Content_2 Mobile_Pos3" />
                    </AdWrapper>
                  )}
                  {index > 12 && index % 12 === 0 && (
                    <AdWrapper>
                      <Ad className="Content_3 Mobile_Pos3" />
                    </AdWrapper>
                  )}
                </KeyWrapper>
              );
            })
          )}
          <div ref={detectorRef} aria-hidden />
          {data.mainFlux?.info ? (
            <Paginator baseUrl={pageInfo.section.url} info={data.mainFlux.info} mode="html" />
          ) : null}
        </MainContent>
        <DownloadSideContent style={{ marginTop: heightHat ? `-${heightHat + 35}px` : '' }}>
          <Ad className="HalfpageAd_1" desktopOnly />
          {dataSponsoSidebar?.isEmpty ? null : (
            <SideContentSpacer>
              {sponsoSidebar ? <Sponso {...sponsoSidebar} hiddenOnMobile /> : <SidebarSponsoredLoading />}
            </SideContentSpacer>
          )}
          {menuOpen && <Overlay />}
          {data.sections?.sectionList?.length > 0 && (
            <TitleCategories onClick={() => setMenuOpen(!menuOpen)}>
              <IconCategories>
                <Categories color="white" width={24} height={24} />
              </IconCategories>
              <span>{t('sdk.template.listing_download.categories')}</span>
            </TitleCategories>
          )}
          <Select
            options={options}
            onChange={(newValues) => callbackPostFilters(newValues)}
            labelledBy={t('sdk.template.listing_download.filter_by_os')}
            multiple
            allLabel={t('sdk.template.listing_download.filter_all_os')}
            icon
          />
          <RefineMenu className={menuOpen ? 'mod-open' : ''}>
            <RefineMenuTop>
              <RefineMenuTopTitle>{t('sdk.filter.refine_my_search')}</RefineMenuTopTitle>
              <RefineMenuClose onClick={() => setMenuOpen(!menuOpen)}>
                <Close color={currentTheme.palette.blueMedium} width={20} height={20} />
              </RefineMenuClose>
            </RefineMenuTop>
            {data.sections?.sectionList?.length > 0 && <SectionNavigation sections={data.sections?.sectionList} />}
          </RefineMenu>
          <DesktopOnly>
            {data.topDownload?.data ? (
              <>
                <Title>{data.topDownload?.data?.info?.title}</Title>
                <ListItemRow data-testid="top-download">
                  {data.topDownload?.data?.products?.productVariantList?.map((product: any) => {
                    const imageProps = {
                      imageId: product.imageId,
                      alt: product.title,
                    };

                    const LinkProps = {
                      href: product.urlCta ?? product.url,
                      text: t('sdk.template.download.download'),
                    };

                    return (
                      <ItemCtaSimple
                        small={false}
                        href={product.url}
                        callback={clickTopApp}
                        key={product.id}
                        title={product.title}
                        image={imageProps}
                        cta={LinkProps}
                        subtitle={product.title}
                      />
                    );
                  })}
                </ListItemRow>
              </>
            ) : null}
          </DesktopOnly>
          <SideContentBottom>
            <Ad className="HalfpageAd_2" desktopOnly />
          </SideContentBottom>
        </DownloadSideContent>
      </Container>
      <Spacer />
    </>
  );
};

export default DownloadListing;
