import React from 'react';
import styled, { css } from 'styled-components';
import { useImageComponent, useLinkComponent } from '../../utils/component';
import { SoftwareProps } from '../types';
import { DEVICE_SIZE, mq } from '../../utils/mq';
import { Cta } from '../cta';
import { useTranslation } from '../../utils/translation';
import { LinkProps } from '../../atoms/types';
import { Tag } from '../../atoms/tag';
import { ListOs } from '../list-os';
import { useThemeSwitcher } from '../../utils/theme-switcher';

const Wrapper = styled.div`
  border-radius: 6px;
  display: grid;
  flex-flow: wrap;
  gap: 8px 0;
  grid-template-columns: 62px 15px 1fr;
  margin: 0 0 20px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      align-items: center;
      display: flex;
      flex-flow: initial;
      gap: 30px;
      margin: 30px 0 0;
    `,
  )}
`;

const WrapperImage = styled.div`
  align-self: start;
  border: solid 1px #dddddd;
  border-radius: 6px;
  box-sizing: content-box;
  grid-column: 1 / 1;

  ${mq.gte(DEVICE_SIZE.LARGE, css``)}
`;

const Informations = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  grid-column: 3 / 4;
  line-height: 29px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 18px;
    `,
  )}
`;

const Title = styled.h3`
  color: ${(props) => (props.theme.isDark ? props.theme.palette.white : props.theme.palette.blackAlt)};
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Description = styled.p`
  order: 1;
  margin: 8px 0 0 -77px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0;
      order: initial;
    `,
  )}
`;

const Download = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-left: auto;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      grid-column: 1 / 4;
      width: 100%;

      * {
        display: block;
        width: 100%;
      }
    `,
  )}
`;

const UpdateDesktop = styled.div`
  display: none;
  color: ${(props) => (props.theme.isDark ? 'white' : '#777')};
  font-size: 12px;
  line-height: 24px;
  text-align: center;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
    `,
  )}
`;

const UpdateMobile = styled(UpdateDesktop)`
  display: block;
  grid-column: 1 / 4;
  text-align: left;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const Line = styled.div`
  display: flex;
  gap: 30px;
`;

const Left = styled.div``;

export const ItemSponsored = ({ ...props }: SoftwareProps) => {
  const { title, image, description, url, landingPage, updatedAt } = props;

  const LinkComponent = useLinkComponent();
  const ImageComponent = useImageComponent();
  const { currentTheme } = useThemeSwitcher();
  const t = useTranslation();

  const cta: LinkProps = {
    href: landingPage || url,
    openInNewWindow: true,
    rel: ['nofollow'],
  };

  const propsIcon = {
    color: currentTheme.isDark ? currentTheme.palette.white : currentTheme.palette.blue,
    width: 17,
    height: 17,
  };

  return (
    <Wrapper>
      <Left>
        <WrapperImage>
          <LinkComponent href={landingPage || url}>
            <ImageComponent
              imageId={image.imageId}
              alt={image.alt}
              mobileHeight={60}
              mobileWidth={60}
              desktopHeight={150}
              desktopWidth={150}
              lazy={false}
            />
          </LinkComponent>
        </WrapperImage>
        <UpdateDesktop>
          {t('design.download.update')} : {updatedAt}
        </UpdateDesktop>
      </Left>
      <Informations>
        <LinkComponent {...cta}>
          <Title>{title}</Title>
        </LinkComponent>
        <Description>{description}</Description>
        <Line>
          <Tag className="mod-without-link">{t('design.download.sponsored')}</Tag>
          <ListOs {...propsIcon} os={['Windows', 'Android', 'Mac', 'iOS']} />
        </Line>
      </Informations>
      <UpdateMobile>
        {t('design.download.update')} : {updatedAt}
      </UpdateMobile>
      <Download>
        <Cta className="mod-green" {...cta}>
          {t('design.download.download')}
        </Cta>
      </Download>
    </Wrapper>
  );
};
