import React from 'react';
import { LogoProps } from './types';

export function Close({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        d="M13.5 12l3.344 3.375a.416.416 0 0 1 .156.344c0 .146-.052.26-.156.344l-.782.78a.416.416 0 0 1-.343.157.416.416 0 0 1-.344-.156L12 13.5l-3.375 3.344a.416.416 0 0 1-.344.156.416.416 0 0 1-.344-.156l-.78-.781A.416.416 0 0 1 7 15.719c0-.146.052-.26.156-.344L10.5 12 7.156 8.625A.416.416 0 0 1 7 8.281c0-.146.052-.26.156-.343l.781-.782A.416.416 0 0 1 8.281 7c.146 0 .26.052.344.156L12 10.5l3.375-3.344A.416.416 0 0 1 15.719 7c.146 0 .26.052.343.156l.782.782A.416.416 0 0 1 17 8.28c0 .146-.052.26-.156.344L13.5 12z"
        fill={color}
      />
    </svg>
  );
}

export default Close;
