import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';

export const HeadingOne = styled.h1`
  color: ${(props) => props.theme.colors.title};
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 30px;
  font-weight: 700;
  line-height: 1.38;
  margin: 0 0 15px;
  grid-column: 2 / 3;

  &.mod-no-margin {
    margin: 0;
  }

  &.mod-extra-margin {
    margin: 0 0 var(--spacer);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      grid-column: 1 / 9;
      font-size: 48px;

      &.mod-smaller {
        font-size: 40px;
      }

      &.mod-x-smaller {
        font-size: 34px;
      }
    `,
  )}
`;
