import React from 'react';
import styled, { css } from 'styled-components';
import { useImageComponent, useLinkComponent } from '../../utils/component';
import { useThemeSwitcher } from '../../utils/theme-switcher';
import { SoftwareProps } from '../types';
import { DEVICE_SIZE, mq } from '../../utils/mq';
import { Cta } from '../cta';
import { useTranslation } from '../../utils/translation';
import { ListOs } from '../list-os';
import { Stars } from '../stars';

const Wrapper = styled.div`
  align-items: start;
  border-radius: 10px;
  display: grid;
  flex-flow: wrap;
  gap: 8px 0;
  grid-template-columns: 62px 15px 1fr;
  margin: 0 0 30px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      align-items: center;
      display: flex;
      flex-flow: initial;
      gap: 30px;
      margin: 30px 0 0;
    `,
  )}
`;

const WrapperImage = styled.div`
  border: solid 1px #dddddd;
  border-radius: 6px;
  box-sizing: content-box;
  grid-column: 1 / 1;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      border-radius: 10px;
      padding: 14px;
    `,
  )}
`;

const Informations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  grid-column: 3 / 4;
  line-height: 29px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 18px;
      gap: 8px;
      margin: 0 0 24px;
    `,
  )}
`;

const InformationsOs = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 5px 0 5px -77px;
    `,
  )}
`;

const Title = styled.h3`
  color: ${(props) => (props.theme.isDark ? props.theme.palette.white : props.theme.palette.blackAlt)};
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Description = styled.p`
  margin: 5px 0 0 -77px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0;
      order: initial;
    `,
  )}
`;

const Download = styled.div`
  display: none;
  flex-direction: column-reverse;
  gap: 6px;
  margin: 0 0 24px;
  order: 3;

  a {
    padding: 10px 13px;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      flex-direction: column;
      margin-left: auto;
      order: initial;

      a {
        border: none;
      }
    `,
  )}
`;

const Left = styled.div``;

const UpdateDesktop = styled.div`
  color: ${(props) => (props.theme.isDark ? 'white' : '#777')};
  display: none;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
    `,
  )}
`;

const UpdateMobile = styled(UpdateDesktop)`
  display: block;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const Review = styled.div`
  display: flex;
  flex-flow: wrap;
  font-size: 16px;
  line-height: 25px;

  strong {
    margin: 0 5px;
  }
`;

const EmptyReview = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      height: 25px;
    `,
  )}
`;

export const Item = ({ ...props }: SoftwareProps) => {
  const LinkComponent = useLinkComponent();
  const ImageComponent = useImageComponent();

  const { currentTheme } = useThemeSwitcher();
  const t = useTranslation();

  const { title, image, description, updatedAt, url, userRatingNb, userRating } = props;

  const propsIcon = {
    color: currentTheme.isDark ? currentTheme.palette.white : currentTheme.palette.blue,
    width: 17,
    height: 17,
  };

  return (
    <Wrapper>
      <Left>
        <WrapperImage>
          <LinkComponent href={url}>
            <ImageComponent
              imageId={image.imageId}
              alt={image.alt}
              mobileHeight={60}
              mobileWidth={60}
              desktopHeight={120}
              desktopWidth={120}
            />
          </LinkComponent>
        </WrapperImage>
        <UpdateDesktop>
          {t('design.download.update')} : {updatedAt}
        </UpdateDesktop>
      </Left>
      <Informations>
        <Title>
          <LinkComponent href={url}>{title}</LinkComponent>
        </Title>
        {userRating ? (
          <Review>
            <Stars max={5} rating={userRating} />
            <strong>{userRating.toFixed(1).replace('.', ',')}</strong> (
            {`${userRatingNb} ${t('sdk.template.download.marks')}`})
          </Review>
        ) : (
          <EmptyReview />
        )}
        <Description>{description}</Description>
        <InformationsOs>
          <ListOs {...propsIcon} os={props.os} />
        </InformationsOs>
      </Informations>
      <Download>
        <Cta className="mod-green" href={url} noLink>
          {t('design.download.download')}
        </Cta>
      </Download>
      <UpdateMobile>
        {t('design.download.update')} : {updatedAt}
      </UpdateMobile>
    </Wrapper>
  );
};
