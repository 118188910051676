import React from 'react';
import styled from 'styled-components';

import Windows from '../icons/os/windows';
import MacOs from '../icons/os/macos';
import Linux from '../icons/os/linux';
import Ios from '../icons/os/ios';
import Web from '../icons/os/web';
import Android from '../icons/os/android';

export interface ListOsProps {
  width: number;
  height: number;
  color: string;
  os: string[];
}

const Line = styled.div`
  align-items: center;
  display: flex;
  gap: 15px;
`;

export const ListOs = ({ width, height, color, os }: ListOsProps) => {
  const propsIcon = {
    width,
    height,
    color,
  };

  return (
    <Line>
      {os?.map((osItem: string, key) => {
        switch (osItem) {
          case 'Windows':
            return <Windows key={key} {...propsIcon} />;
          case 'macOS':
            return <MacOs key={key} {...propsIcon} />;
          case 'Linux':
            return <Linux key={key} {...propsIcon} />;
          case 'Android':
            return <Android key={key} {...propsIcon} />;
          case 'iOS':
            return <Ios key={key} {...propsIcon} />;
          default:
            return <Web key={key} {...propsIcon} />;
        }
      })}
    </Line>
  );
};
