import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';

export const HeadingThree = styled.h3`
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.38;
  margin: var(--spacer-s) 0;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 22px;
    `,
  )}
`;
