import React from 'react';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { formatDateWithTime } from './utils';

interface Props extends React.HTMLAttributes<any> {
  dateTime: string;
  pattern?: string;
}

export function DateDisplayAsString({ dateTime, pattern = "dd MMMM yyyy 'à' HH'h'mm" }: Props) {
  const t = useTranslation();

  if (!dateTime) {
    return null;
  }

  return formatDateWithTime(dateTime, pattern, t('locale'));
}

export function DateDisplay({ dateTime, pattern = "dd MMMM yyyy 'à' HH'h'mm" }: Props) {
  return <>{DateDisplayAsString({ dateTime, pattern })}</>;
}
