import React from 'react';
import styled from 'styled-components';
import { LinkProps } from '../atoms/types';
import { useImageComponent, useLinkComponent } from '../utils/component';

export interface ImageCtaProps {
  image: { imageId: string; alt: string };
  title: string;
  subtitle: string;
  cta: LinkProps;
  href: string;
  small: boolean;
  lazy?: boolean;
  callback?: (title: string) => void;
}

interface CssProps {
  small: boolean;
}

const Box = styled.li`
  list-style: none;
`;

const BoxWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 13px;
  width: 100%;
`;
const BoxImageText = styled.div`
  align-items: center;
  margin: 0;
  display: flex;
`;

const BoxImage = styled.span`
  border: 1px solid #e5e8ee;
  border-radius: 15px;
  margin: 0 10px 0 0;
`;

const BoxText = styled.span`
  margin: 0 10px 0 0;
`;

const BoxButton = styled.span`
  margin: 0 0 0 auto;
  border-radius: 6px;
`;

const Button = styled.span`
  background-color: ${(props) => props.theme.palette.red};
  border-radius: 6px;
  color: ${(props) => props.theme.palette.white};
  font-weight: bold;
  position: relative;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 10px 12px;
  white-space: nowrap;

  &:hover {
    background-color: ${(props) => props.theme.palette.darkRed};
  }
`;

const Title = styled.span<CssProps>`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.62;
  opacity: 0.75;
`;

export const ItemCtaSimple = ({ image, title, cta, small, callback, href }: ImageCtaProps) => {
  const ImageComponent = useImageComponent();
  const LinkComponent = useLinkComponent();

  const imageProps = {
    ...image,
    mobileWidth: 40,
    mobileHeight: 40,
    desktopWidth: 40,
    desktopHeight: 40,
  };

  const mainHref = href ?? cta?.href;

  // 3 case mainHref == cta?.href, mainHref != cta?.href & no cta

  if (mainHref === cta?.href) {
    return (
      <Box>
        <LinkComponent href={href} onClick={callback ? () => callback(title) : null}>
          <BoxWrapper>
            <BoxImage>
              <ImageComponent {...imageProps} />
            </BoxImage>
            <BoxText>
              <Title small={small}>{title}</Title>
            </BoxText>
            {cta.text ? (
              <BoxButton>
                <Button>{cta.text}</Button>
              </BoxButton>
            ) : null}
          </BoxWrapper>
        </LinkComponent>
      </Box>
    );
  }

  return (
    <Box>
      <BoxWrapper>
        <LinkComponent href={href} onClick={callback ? () => callback(title) : null}>
          <BoxImageText>
            <BoxImage>
              <ImageComponent {...imageProps} />
            </BoxImage>
            <BoxText>
              <Title small={small}>{title}</Title>
            </BoxText>
          </BoxImageText>
        </LinkComponent>
        {cta?.href && cta.text ? (
          <BoxButton>
            <LinkComponent href={cta?.href} rel={['nofollow']} openInNewWindow>
              <Button>{cta.text}</Button>
            </LinkComponent>
          </BoxButton>
        ) : null}
      </BoxWrapper>
    </Box>
  );
};
