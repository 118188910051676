import styled from 'styled-components';

export const ListIndented = styled.ul`
  margin: 0 0 15px 20px;
  list-style: none;

  li {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      border-right: 2px solid ${(props) => props.theme.colors.primary};
      border-bottom: 2px solid ${(props) => props.theme.colors.primary};
      width: 8px;
      height: 8px;
      top: 8px;
      left: -20px;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
`;

export const ListOrdered = styled.ol`
  margin: 0 0 15px 20px;
`;

export const ListItem = styled.li`
  &:before {
    top: 14px !important;
  }
`;
