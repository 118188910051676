import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

export type TabletProps = {
  className?: string;
  children: ReactNode;
};

const Tablet = styled.span`
  background: ${(props) => (props.theme.isDark ? props.theme.palette.darkBlack : 'rgb(229, 232, 238)')};
  border-radius: 3px;
  color: ${(props) => (props.theme.isDark ? props.theme.palette.white : 'rgb(119, 119, 119)')};
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  transition: all 0.3s;

  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: white;

    &.mod-without-link {
      background: ${(props) => (props.theme.isDark ? props.theme.palette.darkBlack : 'rgb(229, 232, 238)')};
      color: ${(props) => (props.theme.isDark ? props.theme.palette.white : 'rgb(119, 119, 119)')};
    }
  }

  a {
    display: block;
    padding: 4px 10px;
  }

  &.mod-without-link {
    padding: 4px 10px;
  }

  &.mod-blue {
    background: ${(props) => (props.theme.isDark ? props.theme.palette.darkBlack : props.theme.palette.blue)};
    color: white;

    &:hover {
      background: ${(props) => props.theme.palette.darkBlue};
    }
  }
`;

export function Tag({ className, children }: PropsWithChildren<TabletProps>) {
  return <Tablet className={className}>{children}</Tablet>;
}
